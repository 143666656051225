<template>
  <div>
    <t-form ref="form" @submit.prevent>
      <MovingFromAddressFormPart @result="onResult" @change="onChange" />

      <h2 class="form-modal__part-title">Tipo de vivienda</h2>

      <MovingFromPropertyTypeFormPart @result="onResult" @change="onChange" />
    </t-form>

    <FormErrorMessages :error-messages="errorMessages" />
  </div>
</template>

<script>
import FormErrorMessages from 'chimera/all/components/form/FormErrorMessages'
import AbstractFormStep from 'chimera/all/components/form/steps/AbstractFormStep'
import MovingFromPropertyTypeFormPart from 'chimera/moving/components/form/parts/movingFromPropertyType/MovingFromPropertyTypeFormPart'
import MovingFromAddressFormPart from 'chimera/moving/components/form/parts/movingFromAddress/MovingFromAddressFormPart.es'
import NextStep from '~/pages/solicitudes-de-cotizacion/mudanza-hasta'

export default {
  name: 'MovingFromFormStep',

  components: {
    MovingFromAddressFormPart,
    MovingFromPropertyTypeFormPart,
    FormErrorMessages,
  },

  extends: AbstractFormStep,

  /**
   * @returns {object}
   */
  data() {
    return {
      willTransitionOnValid: true,
    }
  },

  /**
   * Pass through the submit event given from FormModal
   */
  created() {
    this.$nuxt.$on('submit', this.onSubmit)
  },

  /**
   * Clean up before component is destroyed
   */
  destroyed() {
    this.$nuxt.$off('submit')
  },

  methods: {
    /**
     * Transition
     */
    transition() {
      this.routeTo(NextStep)
    },
  },
}
</script>
