<template>
  <div>
    <AddressFormGroup v-on="$listeners" />
  </div>
</template>

<script>
import AddressFormGroup from 'chimera/moving/components/form/parts/address/AddressFormGroup'

export default {
  name: 'MovingFromAddressFormPart',

  components: {
    AddressFormGroup,
  },
}
</script>
