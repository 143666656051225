<template>
  <div class="page-index">
    <Header
      background-image="images/hero.jpg"
      class="header--center header--center-bottom header--white--text"
    >
      <div class="container">
        <h2 class="card__title">
          {{ cardTitle }}
        </h2>

        <span class="card__text">
          {{ cardText }}
        </span>

        <TCard class="header__card">
          <div class="card__form">
            <PostalFormStep :progress-value="0" />
          </div>
        </TCard>

        <span class="card__subtext">
          {{ cardSubtext }}
        </span>
      </div>
    </Header>

    <HomeBlock id="usps">
      <Usps :usps="usps" />
    </HomeBlock>
  </div>
</template>

<script>
import AbstractHomePage from 'chimera/all/components/page/AbstractHomePage'
import HomeBlock from 'chimera/all/components/HomeBlock'
import Usps from 'chimera/all/themes/blueflow/components/Usps'
import Header from 'chimera/all/themes/blueflow/components/layout/Header'
import PostalFormStep from '~/components/form/steps/postal/PostalFormStep'

export default {
  name: 'ComparamudanzasHomePage',

  components: {
    Header,
    HomeBlock,
    PostalFormStep,
    Usps,
  },

  extends: AbstractHomePage,

  props: {
    customDimension: {
      type: String,
      default: undefined,
    },
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Compara de mudanzas',
      headDescription:
        'Compara empresas de mudanzas en Comparamudanzas.es. Cotizaciones rápidas, confiables y únicas de su región. Ahorre hasta un 40% en costos de mudanza.',
      path: '/',
      usps: [
        {
          title: 'Rellena el formulario',
          text: 'Indícanos lo que necesitas y te pondremos en contacto con los mejores profesionales.',
          logo: require('chimera/all/themes/blueflow/images/usps/pencil.svg'),
        },
        {
          title: 'Obtenga cotizaciones gratis',
          text: 'Recibirás, como máximo, 6 ofertas de profesionales en tu zona. Así puedes ahorrar hasta un 40%',
          logo: require('chimera/all/themes/blueflow/images/usps/offer.svg'),
        },
        {
          title: 'Escoge al mejor profesional',
          text: 'Hemos seleccionado los mejores profesionales para ti, ahora sólo tienes que escoger',
          logo: require('chimera/all/themes/blueflow/images/usps/user.svg'),
        },
      ],
      cardTitle: 'Encuentra un profesional en tu provincia',
      cardText: '¡Compara presupuestos antes de decidir y ahorra hasta un 40%!',
      cardSubtext: 'Compara hasta 6 empresas en tu provincia',
    }
  },

  methods: {
    /**
     * add custom-dimension-2 to lead store
     */
    async addCustomDimension2() {
      await this.$store.dispatch('lead/add', {
        key: 'custom-dimension-2',
        value: this.customDimension,
      })
    },
  },
}
</script>

<style scoped>
.page-index .header--white--text .header__card {
  @apply max-w-[440px];
}
</style>
